body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1.5rem;
    color: #333;
    background-color: #415D43;
}

h1 {
    margin-top: 10rem;
    font-size: 3rem;
    color: aliceblue;
}